<template>
    <div style="display: flex;">
        <div class="pet-detail-box">
            <div class="shop_box">
                <div style="text-align: center">
                    <p style="padding-bottom: 20px;">活动封面图</p>
                    <el-upload
                            class="cover-uploader"
                            action="/officialWebsiteMgr/resource/file/addImage"
                            :show-file-list="false"
                            :on-change="handleChange"
                            :on-success="handleSuccess"
                            ref="upload"
                    >
                        <img v-if="!isUpload" :src="formatImg(showImg)" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div>
                    <el-input v-model="activityName" placeholder="活动名称"></el-input>
                </div>
                <div>
                    <el-input v-model="activityDesc" placeholder="活动简介"></el-input>
                </div>
                <div>
                    时间：
                        <el-date-picker
                            style="width:93%;"
                            :editable="false"
                            v-model="time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div class="content">
                    <div v-for="(item, index) of activityContent" :key="index" class="cont_c">
                        <div :class="item.type">
                            <p v-if="item.type == 'words'" v-show="index!=editIndex">{{ item.cont }}</p>
                            <img :src="formatImg(item.cont)" v-if="item.type == 'img'" />
                            <div class="editTxt" v-show="editIndex==index">
                                <el-input type="textarea" resize="none" autosize placeholder="请输入内容" v-model="editTxt">
                                </el-input>
                                <p style="text-align: right;margin-top:5px;">
                                    <el-button size="mini" @click="closeEdit(index)">取消</el-button>
                                    <el-button size="mini" type="primary" @click="saveEdit(index)">保存</el-button>
                                </p>
                            </div>
                        </div>
                        <div class="cont_btn">
                            <el-button size="mini" round :disabled="index == 0" @click="indexUp(index)" >上移</el-button>
                            <el-button size="mini" round :disabled="index == activityContent.length - 1" @click="indexDown(index)"
                            >下移</el-button>
                            <el-button size="mini" round @click="indexTop(index)">置顶</el-button>
                            <el-button size="mini" round @click="indexBtm(index)">置底</el-button>
                            <el-button size="mini" round @click="showEdit(index)" v-if="item.type=='words'">编辑</el-button>
                            <el-button size="mini" round @click="indexDel(index)">删除</el-button
                            >
                        </div>
                    </div>
                </div>
                <div class="content_btn">
                    <div v-show="allTxt==true" class="allTxtCont">
                        <el-input v-model="txt" type="textarea" placeholder="文字输入区域" resize="none" autosize></el-input>
                        <p>
                            <el-button round size="mini" @click="allTxt=false" class="cont_btn">取消</el-button>
                            <el-button type="primary" round size="mini" @click="addText" class="cont_btn">确定</el-button>
                        </p>
                    </div>

                    <div class="cont_btn_box">
                        <el-upload
                                action="/officialWebsiteMgr/resource/file/addImage"
                                :on-success="addImg"
                                :show-file-list="false"
                                class="cont_btn"
                        >
                            <el-button type="primary" round size="mini">添加图片</el-button>
                        </el-upload>
                        <el-button type="primary" round size="mini" @click="allTxt=true">添加文字</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pet_fun">
            <div>
                <!--<el-button type="warning">清空</el-button>-->
                <el-button type="primary" @click="save(0)">保存</el-button>
                <el-button type="primary" @click="save(1)">发布</el-button>
                <el-button type="info" @click="goBackList">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import instance from '@/services/instance';

export default {
    name: "activityCreate",
    data() {
        return {
            isUpload:true,
            activityName:"",//活动名称
            activityDesc:"",//活动简介
            activityTimeStr:"",//开始时间
            activityEndTimeStr:"",//结束时间
            activityStatus:"",//状态
            showImg:"",//封面
            activityContent:[],
            time:[],
            txt:"",
            status:"",
            editIndex:-1,
            editTxt:"",
            allTxt:false,
        };
    },
    created(){
        this.status = this.$route.params.type;
        if(this.status=="edit"){
            this.getDetail()
        }
    },
    methods:{
        handleChange(file, fileList) {
            this.fileList = fileList.slice(-1)
        },
        handleSuccess(res) {
            if (res.code == 0) {
                this.isUpload=false;
                this.showImg = res.data;
            }
        },
        formatImg(url){
            return "/officialWebsiteMgr/resource/file/getImage?fileName="+url;
        },
        addText() {
            if (this.txt == "") {
                this.$message.error("添加内容为空");
                return false;
            } else {
                let ary = { type: "words", cont: this.txt };
                this.activityContent.push(ary);
                this.txt = "";
            }
        },
        addImg(res) {
            if (res.code == 0) {
                let ary = { type: "img", cont: res.data };
                this.activityContent.push(ary);
            } else {
                this.$message.error("上传失败!");
            }
        },
        showEdit(index){
            this.editIndex=index;
            this.editTxt=this.activityContent[index].cont;
        },
        closeEdit(){
            this.editIndex=-1;
        },
        saveEdit(index){
            this.activityContent[index].cont = this.editTxt;
            this.editIndex=-1;
            this.editTxt="";
        },
        indexUp(index) {
            this.activityContent[index] = this.activityContent.splice(
                index - 1,
                1,
                this.activityContent[index]
            )[0];
        },
        indexTop(index){
            this.activityContent.unshift(this.activityContent.splice(index,1)[0]);
        },
        indexBtm(index){
            this.activityContent.push(this.activityContent.splice(index, 1)[0]);
        },
        indexDown(index) {
            this.activityContent[index] = this.activityContent.splice(
                index + 1,
                1,
                this.activityContent[index]
            )[0];
        },
        indexDel(index) {
            this.activityContent.splice(index, 1);
        },
        save(status){
            if(this.showImg==""){
                this.$message.error("请上传封面图");
                return false;
            }
            if(this.activityName==""){
                this.$message.error("请输入活动名称");
                return false;
            }
            if(this.activityDesc==""){
                this.$message.error("请输入活动简介");
                return false;
            }
            if(this.time==null || this.time.length==0){
                this.$message.error("请选择活动时间");
                return false;
            }
            if(this.activityContent.length==0){
                this.$message.error("请填写活动内容");
                return false;
            }
            var sdata = {
                showImg: this.showImg,
                activityName:this.activityName,
                activityDesc:this.activityDesc,
                activityTimeStr:this.time[0],
                activityEndTimeStr:this.time[1],
                activityStatus: status,
                activityContent: JSON.stringify(this.activityContent),
            }
            let url = '/officialWebsiteMgr/resource/activity/add';
            if(this.status=="edit"){
                url = "/officialWebsiteMgr/resource/activity/edit";
                sdata.activityId = this.$route.params.id;
            }
            instance
                .post(url,sdata)
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("成功！");
                        this.$router.push({
                            name: "activityList",
                        });
                    }
                })
        },
        getDetail(){
            instance
                .post('/officialWebsiteMgr/resource/activity/detail',{activityId:this.$route.params.id})
                .then((res) => {
                    if (res.code == 0) {
                        let data=res.data;
                        this.activityName=data.activityName;
                        this.activityDesc=data.activityDesc;
                        this.showImg=data.showImg;
                        this.isUpload=false;
                        this.activityContent=JSON.parse(data.activityContent);

                        this.$set(this.time, 0, data.activityTimeStr+":00");
                        this.$set(this.time, 1, data.activityEndTimeStr+":59");
                        console.log(this.time)
                    }
                })
        },
        goBackList(){
            this.$router.push({
                name: 'activityList',
            })
        }
    }
}
</script>

<style  lang="scss">
.shop_box{
    padding: 10px 20px 20px 30px;
}
.shop_box>div{
    margin-bottom: 20px;
}
.cover-uploader{
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409EFF;
    }
}
.content_btn {
    margin-top: 10px;
    text-align: right;
    .allTxtCont{
        overflow:hidden;
        background: #f7f7f7;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        .el-textarea{
            margin-bottom: 10px;
        }
    }
    .cont_btn_box{
        height:30px;
        margin-top:10px;
        .cont_btn{
            float: right;
            margin-left:20px;
        }
    }
}
.cont_c{
    padding: 20px 20px 45px 20px;
    margin-bottom: 20px;
    .cont_btn{
        float: right;
        margin-top:10px;
        display: none;
    }
    .img{
        text-align: center;
    }
}

.cont_c:hover{
    background-color: #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .cont_btn{
        display: block;
    }
}
</style>